<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="d-flex align-items-center mb-2 mb-lg-0">
                <div class="me-2">
                  <i class="uil uil-file-shield-alt display-6 text-primary"></i>
                </div>
                <div class="">
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    {{ title }}
                  </h4>
                  <small class="d-block font-size-13 text-muted fw-normal"
                    >Responsive technical support for your needs.</small
                  >
                </div>
              </div>
              <!-- <div class="ms-auto">
                  <a :href="`skype:${skype_id}?chat`" class="btn btn-info">
                  <i class="uil uil-skype-alt"></i> Support on Skype</a>
              </div> -->
            </div>
            
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <p class="text-muted">Expert technical support tailored to your needs. Our dedicated team is here to assist you promptly, ensuring seamless resolution and optimal performance for your technology challenges.</p>
                  <p class="text-muted">If you need assistance, please reach out to us via Skype and provide the "Code for Support."</p>

                  <p class="text-muted">Code for Support: <code class="font-size-14">{{code_for_support_skype || '-'}}</code></p>
                  <!-- Skype chat button -->
                  <a :href="`skype:${skype_id}?chat`" class="btn btn-info me-2 mb-2">
                  <i class="uil uil-skype-alt"></i> Support on Skype</a>
                  <a :href="`https://t.me/${telegram_id}`" target="_blank" class="btn btn-info mb-2">
                  <i class="uil uil-telegram-alt"></i> Support on Telegram</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="''" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import { 
  required, 
  //minLength,
  //sameAs, 
  //helpers 
} from "vuelidate/lib/validators";
  //const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
/**
 * Technical Support 
 */
 const PageTitle = "Technical Support"

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      username: "",
      merchant_code: "",
      merchant_key: "",
      account_type:"",
      code_for_support_skype:"",
      formData: {
        name:""
      },
      submitted:false,
      loading:false,
      buttonLoading:[false,false,false],
      deleteLoading:false,
      create_modify_permission:false,
      skype_id:"",
      telegram_id:"",
    };
  },
  validations: {
    formData: {
      name: {
        required,
      }
    },
  },
  middleware: "authentication",
  async mounted(){
   
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    await this.reload()
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    
  }, 
  created(){
   
  },
  methods:{
      async getData(){
        this.$Progress.start();
        this.loading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        axios({
            method: "post",
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            console.log(error);
        });
      },
      submit(){
        this.loading = true
        this.$v.formData.$touch();
        console.log((this.$v.formData.$invalid))
        if (this.$v.formData.$invalid) {
          return;
        } else {
          this.$Progress.start();
          this.loading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("name", this.formData.name);
          axios({
              method: "post",
              url:  appConfig.DemoAPI,
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'xxxx Updated',
                  html: 'xxxx has been successfully updated!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  console.log("success")
                  this.getData()
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.loading = false;
              this.submitted = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.loading = false;
                this.submitted = false
                this.$Progress.finish();
                console.log(error);
        });
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
          this.username = data.username;
          this.merchant_key = data.merchant_info.merchant_key;
          this.merchant_code = data.merchant_info.merchant_code;
          if (this.account_type=='reseller'){
            this.code_for_support_skype = data.reseller_info.code_for_support_skype
          }
          else if (this.account_type=='merchant'){
            this.code_for_support_skype = data.merchant_info.code_for_support_skype;
          }
          else{
            this.code_for_support_skype = ''
          }
          this.skype_id = data.skype_id;
          this.telegram_id = data.telegram_id
          this.formData.name = data.name;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  }
};
</script>